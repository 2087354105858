import { useEffect } from 'react';

const AboutRocker = () => {
  useEffect(() => {
    window.location.replace('https://corporate.rocker.com/about-rocker/');
  }, []);

  return null;
};

export default AboutRocker;
